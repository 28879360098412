<script>
import { translateField } from '@/application/util/translation.ts';

export default {
  name: 'DisplayMixin',
  inject: {
    displayGroup: {
      default: undefined,
    },
  },
  props: {
    field: {
      required: false,
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
  computed: {
    translatedLabel() {
      if (this.label) {
        return this.label;
      }
      if (this.displayGroup && this.displayGroup.languagePrefix && this.field) {
        return translateField(this.field, this.displayGroup.languagePrefix);
      }
      return this.label || this.field;
    },
  },
};
</script>
