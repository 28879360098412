<template>
  <PageLayout>
    <template #header>
      <k-title>{{ puzzle.name }}</k-title>
    </template>
    <template #flow-actions>
      <v-btn
        class="mx-1"
        color="primary"
        depressed
        tile
        @click="openUpdate"
      >
        {{ $t('actions.updateResource', { resource: $tc('puzzle.title') }) }}
      </v-btn>
      <PuzzleForm
        v-model="updateDialog"
        :request="updateRequest"
        :title="$tc('puzzle.title',1)"
        :values="updateFormValues"
        is-update-form
        @change="onPuzzleChange"
      />
    </template>
    <template
      v-if="puzzle"
      #default
    >
      <v-row>
        <v-col class="col-4">
          <k-dossier>
            <k-display-group language-prefix="puzzle.fields">
              <v-row>
                <v-col>
                  <KTextDisplay field="brand">
                    {{ puzzle.brand }}
                  </KTextDisplay>
                  <KTextDisplay field="serial">
                    {{ puzzle.serial }}
                  </KTextDisplay>
                  <KTextDisplay field="pieces">
                    {{ puzzle.pieces }} {{ $t('puzzle.other.pieces') }}
                  </KTextDisplay>
                  <KTextDisplay field="shape">
                    {{ shapeOptions()[puzzle.shapeId - 1].text }}
                  </KTextDisplay>
                  <KTextDisplay field="dimensions">
                    {{ puzzle.width }}{{ $t('puzzle.other.centimeters') }} x {{ puzzle.height }}{{ $t('puzzle.other.centimeters') }}
                  </KTextDisplay>
                </v-col>
              </v-row>
            </k-display-group>
          </k-dossier>
          <br />
          <k-dossier>
            <k-display-group language-prefix="puzzle.fields">
              <v-row>
                <v-col>
                  <KTextDisplay field="difficulty">
                    {{ difficultyOptions()[puzzle.difficultyId - 1].text }}
                  </KTextDisplay>
                  <KTextDisplay field="quality">
                    {{ qualityOptions()[puzzle.qualityId - 1].text }}
                  </KTextDisplay>
                  <KTextDisplay field="status">
                    {{ statusOptions()[puzzle.statusId - 1].text }}
                  </KTextDisplay>
                </v-col>
              </v-row>
            </k-display-group>
          </k-dossier>
          <br>
          <k-dossier v-if="puzzle.remark">
            <k-display-group language-prefix="puzzle.fields">
              <v-row>
                <v-col>
                  <KTextDisplay field="remark">
                    {{ puzzle.remark }}
                  </KTextDisplay>
                </v-col>
              </v-row>
            </k-display-group>
          </k-dossier>
          <br />
          <k-dossier v-if="hasMissingPieces()">
            <k-display-group language-prefix="puzzle.fields">
              <KTextDisplay field="missingPieces">
                <br>
              </KTextDisplay>
              <v-expansion-panels v-model="open">
                <v-expansion-panel
                  v-for="piece in puzzle.missingPieces"
                  :key="piece.id"
                >
                  <v-expansion-panel-header>
                    <v-row>
                      <v-col cols="5">
                        {{ $t('puzzle.missing.fields.row') }}: {{ piece.row }}
                      </v-col>
                      <v-col cols="5">
                        {{ $t('puzzle.missing.fields.column') }}: {{ piece.column }}
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    {{ piece.name }}
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <br>
            </k-display-group>
          </k-dossier>
        </v-col>
        <v-col
          v-if="puzzle.photo"
          class="col-8"
          align="center"
          justify="center"
        >
          <k-dossier>
            <VImg
              v-if="puzzle.photo"
              contain="true"
              :src="`${puzzle.photo.url}`"
              :lazy-src="`${puzzle.photo.url}&type=lazy`"
            />
            <br>
          </k-dossier>
        </v-col>
      </v-row>
    </template>
  </PageLayout>
</template>

<script>
import Puzzle from '@/application/models/Puzzle.js';
import PageLayout from '@/components/layout/PageLayout.vue';
import eventBus from '@/application/eventBus.ts';
import { shapes } from '@/application/enums/shapes.ts';
import { qualities } from '@/application/enums/qualities.ts';
import { difficulties } from '@/application/enums/difficulties.ts';
import { statuses } from '@/application/enums/statuses.ts';
import { show, update } from '@/modules/puzzle/api/puzzle.js';
import PuzzleForm from '@/modules/puzzle/components/PuzzleForm.vue';
import KTitle from '@/components/layout/KTitle.vue';
import KDossier from '@/components/layout/KDossier.vue';
import KDisplayGroup from '@/components/crud/display/KDisplayGroup.vue';
import KTextDisplay from '@/components/crud/display/KTextDisplay.vue';
import { getTranslatedEnumOptions } from '@/application/util/enum.js';

export default {
  name: 'PuzzleDetail',
  components: {
    KDossier,
    KTitle,
    KDisplayGroup,
    PuzzleForm,
    PageLayout,
    KTextDisplay,
  },
  data: () => ({
    puzzle: new Puzzle(),
    updateFormValues: new Puzzle(),
    updateDialog: false,
  }),
  computed: {
  },
  watch: {
    '$route.params.puzzleId'() {
      this.getPuzzle();
      if (typeof this.$refs.view.reload === 'function') {
        this.$refs.view.reload();
      }
    },
  },
  created() {
    this.getPuzzle();
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'puzzle.index' },
        text: this.$tc('puzzle.title', 2),
      },
      {
        exact: true,
        to: { name: 'puzzle.show' },
        text: this.puzzle.name,
      }],
    );
  },
  methods: {
    onPuzzleChange() {
      this.getPuzzle();
    },
    async getPuzzle() {
      const response = await show(this.$route.params.puzzleId);
      this.puzzle = new Puzzle().mapResponse(response.data.data);
    },
    async openUpdate() {
      this.updateFormValues = new Puzzle();
      const puzzleShowRequest = await show(this.puzzle.id);
      this.updateFormValues.mapResponse(puzzleShowRequest.data.data);
      this.updateDialog = true;
    },
    updateRequest() {
      return update(this.updateFormValues);
    },
    shapeOptions() {
      return getTranslatedEnumOptions(shapes, 'puzzle.shapes');
    },
    qualityOptions() {
      return getTranslatedEnumOptions(qualities, 'puzzle.qualities');
    },
    difficultyOptions() {
      return getTranslatedEnumOptions(difficulties, 'puzzle.difficulties');
    },
    statusOptions() {
      return getTranslatedEnumOptions(statuses, 'puzzle.statuses');
    },
    hasMissingPieces() {
      return this.puzzle.numberOfMissingPieces > 0;
    },
  },
};
</script>
