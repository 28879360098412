<template>
  <div class="k-display-group">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'KDisplayGroup',
  props: {
    languagePrefix: {
      required: false,
      type: String,
      default: '',
    },
  },
  provide() {
    return {
      displayGroup: this,
    };
  },
};
</script>
